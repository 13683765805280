<script setup>
import I18N from 'core/i18n';
import Numbers from 'core/numbers';
import { ref, watch } from 'vue';

const valueMin = defineModel('valueMin', { type: Number });
const valueMax = defineModel('valueMax', { type: Number });

const props = defineProps({
  name: String,
  disabled: {
    type: Boolean,
    default: false
  },
  addons: {
    type: Boolean,
    default: true
  }
});

const inputMin = ref(Numbers.format(valueMin.value));
const inputMax = ref(Numbers.format(valueMax.value));

watch(inputMin, () => {
  let value = Numbers.parse(inputMin.value);

  if (value != null) {
    // ensure min value is never greater than max value
    if (valueMax.value != null && value > valueMax.value) {
      value = valueMax.value;
    }

    valueMin.value = value;
    // update input with re-formatted value
    inputMin.value = Numbers.format(value);
  } else {
    valueMin.value = null;
  }
});

watch(inputMax, () => {
  let value = Numbers.parse(inputMax.value);

  if (value != null) {
    // ensure max value is never lesser than min value
    if (valueMin.value != null && value < valueMin.value) {
      value = valueMin.value;
    }

    valueMax.value = value;
    // update input with re-formatted value
    inputMax.value = Numbers.format(value);
  } else {
    valueMax.value = null;
  }
});
</script>

<template>
  <div class="rpvue-NumberRange">
    <!-- TODO rework while moving code to FE lib/app -->
    <div class="input-group flex-nowrap">
      <div v-if="addons" class="input-group-prepend">
        <span class="input-group-text">{{ I18N.t('filter_min') }}</span>
      </div>
      <input type="text"
             class="form-control"
             v-model.lazy="inputMin"
             :name="name + '_min'"
             :disabled="disabled"
             inputmode="decimal">
    </div>
    <div class="input-group flex-nowrap">
      <div v-if="addons" class="input-group-prepend">
        <span class="input-group-text">{{ I18N.t('filter_max') }}</span>
      </div>
      <input type="text"
             class="form-control"
             v-model.lazy="inputMax"
             :name="name + '_max'"
             :disabled="disabled"
             inputmode="decimal">
    </div>
  </div>
</template>
