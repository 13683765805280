import Fetch from 'core/fetch';
import Log from 'core/log';
import { computed, ref, toValue, watch, watchEffect } from 'vue';

const STATE_OK = 'ok';
const STATE_LOADING = 'loading';
const STATE_ERROR = 'error';

export default function usePartials(urlRef, paramsRef) {
  const partials = ref([]);
  const page = ref(1);
  const more = ref(false);
  const state = ref(STATE_OK);

  const hasMore = computed(() => state.value === STATE_OK && more.value);
  const loadMore = () => {
    if (hasMore.value) {
      page.value += 1;
    }
  };

  const isLoading = computed(() => state.value === STATE_LOADING);
  const isError = computed(() => state.value === STATE_ERROR);

  const reload = () => {
    page.value = 0;
    more.value = false;
    state.value = STATE_OK;
  };

  watch([urlRef, paramsRef], () => reload(), {
    deep: true
  });

  watchEffect(async onCleanup => {
    let reload = false;

    // hack to support reload without actual state changes
    if (page.value <= 0) {
      page.value = 1;
      reload = true;
    }

    const url = toValue(urlRef);
    const params = toValue(paramsRef);
    const body = {
      ...params,
      page: String(page.value)
    };

    const controller = new AbortController();
    const signal = controller.signal;

    onCleanup(() => controller.abort());

    try {
      state.value = STATE_LOADING;

      const response = await Fetch.post(url, body, { signal });

      // clear previous partials only after loading new ones to avoid "blinking" of content
      if (reload) {
        partials.value = [];
      }

      if (!response.ok) {
        state.value = STATE_ERROR;

        Log.error('Unexpected response status ' + response.status, {
          source: 'DynamicList',
          url: url,
          requestBody: body,
          responseBody: response.body
        });

        return;
      }

      state.value = STATE_OK;
      more.value = response.body.more;

      partials.value.push({
        id: body.page,
        html: response.body.content
      });
    } catch (e) {
      if (e.name !== 'AbortError') {
        state.value = STATE_ERROR;
        Log.error(e);
      }
    }
  });

  return { partials, loadMore, reload, isLoading, isError };
}
