<script setup>
import $ from 'jquery';
import { onMounted, ref } from 'vue';
import DynamicListMessage from './DynamicListMessage.vue';
import DynamicListPartials from './DynamicListPartials.vue';
import useLazyLoading from './useLazyLoading';
import usePartials from './usePartials';

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  params: Object
});

const marker = ref(null);

const { partials, loadMore, reload, isLoading, isError } = usePartials(() => props.url, () => props.params);

useLazyLoading(marker, isLoading, loadMore);

onMounted(() => {
  // hackish interop with jQuery based components
  $(document).on('rp-content:reload', () => reload());
});

</script>

<template>
  <div class="rpvue-DynamicListContent">
    <div class="rpvue-DynamicListContent-partials">
      <DynamicListPartials :partials="partials"/>
    </div>
    <div class="rpvue-DynamicListContent-marker" ref="marker"></div>
    <div class="rpvue-DynamicListContent-message">
      <DynamicListMessage :loading="isLoading" :error="isError"/>
    </div>
  </div>
</template>
