<script setup>
import $ from 'jquery';
import { onMounted, ref } from 'vue';
import InteractiveElement, { EVENT_ACTIVATE, EVENT_DEACTIVATE } from '../../../components/interactiveElement';

defineProps({
  partials: {
    type: Array,
    required: true
  }
});

const container = ref(null);

onMounted(() => {
  // hackish interop with jQuery based components
  const $container = $(container.value);

  new InteractiveElement($container);

  $container.on(EVENT_ACTIVATE, (_, data) => data.$element.addClass('active'));
  $container.on(EVENT_DEACTIVATE, (_, data) => data.$element.removeClass('active'));
});

</script>

<template>
  <div class="rpvue-DynamicListPartials" ref="container">
    <div class="rpvue-DynamicListPartials-partial" v-for="partial in partials" :key="partial.id" v-html="partial.html">
    </div>
  </div>
</template>
