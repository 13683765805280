<script setup>
import I18N from 'core/i18n';
import { computed, ref } from 'vue';
import DynamicList from '../DynamicList/DynamicList.vue';

const props = defineProps({
  title: String,
  url: {
    type: String,
    required: true
  },
  params: Object
});

const search = ref('');
const fulltext = ref('');

const params = computed(() => {
  return {
    ...props.params,
    fulltext: fulltext.value
  }
});

const onEnter = () => {
  fulltext.value = search.value;
};

</script>

<template>
  <DynamicList :title="title" :url="url" :params="params">
    <template #header>
      <div class="rpvue-SearchableList-search">
        <input type="text"
               class="form-control"
               v-model="search"
               @keyup.enter.prevent="onEnter"
               :placeholder="I18N.t('table_search_placeholder')"/>
      </div>
    </template>
  </DynamicList>
</template>
