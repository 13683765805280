import { useElementVisibility } from '@vueuse/core';
import { nextTick, watch } from 'vue';

export default function useLazyLoading(markerRef, isLoadingRef, loadMore) {
  const isVisible = useElementVisibility(markerRef);

  const loadMoreIfNeeded = () => {
    if (isVisible.value) {
      loadMore();
    }
  };

  // load more if needed on scroll to bottom
  watch(isVisible, () => loadMoreIfNeeded());

  // load more if needed on loading finished
  watch(isLoadingRef, async (_, wasLoading) => {
    if (wasLoading) {
      await nextTick();
      loadMoreIfNeeded();
    }
  });
}
