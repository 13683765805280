import VueRegistry from '../registry';

import ActionableList from './ActionableList/ActionableList.vue';
import Avatar from './Avatar/Avatar.vue';
import Badge from './Badge/Badge.vue';
import Example from './Example.vue';
import NumberRange from './NumberRange/NumberRange.vue';
import QuadroContainer from './QuadroContainer/QuadroContainer.vue';
import SearchableList from './SearchableList/SearchableList.vue';

import {
  ActivityStream,
  PhoneNumber,
  SelectMulti,
  SelectSingle,
  SideMenu,
  UserMenuWrapper,
} from '@realpadsoftware/realpad-crm-frontend-lib';

VueRegistry.registerComponent('ActionableList', ActionableList);
VueRegistry.registerComponent('ActivityStream', ActivityStream);
VueRegistry.registerComponent('Example', Example);
VueRegistry.registerComponent('PhoneNumber', PhoneNumber);
VueRegistry.registerComponent('SearchableList', SearchableList);
VueRegistry.registerComponent('SelectMulti', SelectMulti);
VueRegistry.registerComponent('SelectSingle', SelectSingle);
VueRegistry.registerComponent('SideMenu', SideMenu);
VueRegistry.registerComponent('UserMenuWrapper', UserMenuWrapper);

// TODO replace with components from realpad-crm-frontend-lib
VueRegistry.registerComponent('Avatar', Avatar);
VueRegistry.registerComponent('Badge', Badge);
VueRegistry.registerComponent('NumberRange', NumberRange);
VueRegistry.registerComponent('QuadroContainer', QuadroContainer);
